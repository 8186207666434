@import 'src/styles/font-stack';

.container {
  color: var(--color-white);
  text-align: center;
  padding: 0 1rem;
  margin-top: 15vh;
  margin-bottom: 15vh;

  img {
    margin-bottom: 2rem;
  }

  h1 {
    @include font-intro-32;
    margin-bottom: 0;
  }

  p {
    @include font-intro-18;
  }
}
